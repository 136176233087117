<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据填报</el-breadcrumb-item>
      <el-breadcrumb-item>填报记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <!-- 超级管理员 查看数据操作记录 -->
      <div class="document" v-if="roleName == '超级管理员'">
        <el-tabs type="border-card">
          <el-tab-pane>
            <template #label>数据填报记录</template>
            <span>资料填报</span>
            <el-table :data="document" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="册数" min-width="140px" prop="volume"></el-table-column>
              <el-table-column label="文件材料名称" min-width="120px" prop="documentContentName">
              </el-table-column>
              <el-table-column label="资料说明" prop="documentDescription"></el-table-column>
              <el-table-column label="资料完成情况说明" prop="documentCompleteDescription"></el-table-column>
              <el-table-column label="总数" prop="totalNum"></el-table-column>
              <el-table-column label="编制数" prop="organizationNum"></el-table-column>
              <el-table-column label="监理签认数" prop="supervisorNum"></el-table-column>
              <el-table-column label="设计签认数" prop="designNum"></el-table-column>
              <el-table-column label="建设单位签认数" prop="constructionNum"></el-table-column>
              <el-table-column label="存档原件数" prop="archiveOriginalNum"></el-table-column>
              <el-table-column label="存档复印数" prop="archiveCopyNum"></el-table-column>
              <el-table-column label="上报时间" min-width="120px"><template
                  v-slot="scope"><span>{{ DateFormat(scope.row.createTime) }}</span></template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                  <span v-if="scope.row.status == 1" class="pass">已通过</span>
                  <span v-if="scope.row.status == 0">未审核</span>
                </template>
              </el-table-column>
              <el-table-column label="审核主管">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">无</span>
                  <span v-else>{{ scope.row.reviewUserId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="原因">
                <template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-config-provider :locale="locale">
              <el-pagination @size-change="docSizeChange" @current-change="docCurChange"
                :current-page="docQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="docQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="docTotal">
              </el-pagination>
            </el-config-provider>
            <span>实验填报</span>
            <el-table :data="experiment" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column min-width="160" label="工程名称" prop="projectName"></el-table-column>
              <el-table-column min-width="120" label="材料及实体名称" prop="materialEntityName">
              </el-table-column>
              <el-table-column label="上报时间" min-width="130"><template
                  v-slot="scope"><span>{{ DateFormat(scope.row.createTime) }}</span></template>
              </el-table-column>
              <el-table-column label="检测项目" min-width="160" prop="testItem"></el-table-column>
              <el-table-column label="检测频率" min-width="160" prop="testFrequency"></el-table-column>
              <el-table-column label="现场进度工程量" prop="progressQuantity"></el-table-column>
              <el-table-column label="进度应检数量" prop="actualRequiredQuantity"></el-table-column>
              <el-table-column label="实际检测数量" prop="progressRequiredQuantity"></el-table-column>
              <el-table-column label="已取检测报告数量" prop="obtainedQuantity"></el-table-column>
              <el-table-column label="未取检测报告数量" prop="notObtainedQuantity"></el-table-column>
              <el-table-column label="检测合格数量" prop="passQuantity"></el-table-column>
              <el-table-column label="检测不合格数量" prop="notPassQuantity"></el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                  <span v-if="scope.row.status == 1" class="pass">已通过</span>
                  <span v-if="scope.row.status == 0">未审核</span>
                </template>
              </el-table-column>
              <el-table-column label="审核主管">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">无</span>
                  <span v-else>{{ scope.row.reviewId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="原因">
                <template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="expSizeChange" @current-change="expCurChange"
                :current-page="expQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="expQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="expTotal">
              </el-pagination>
            </el-config-provider>
          </el-tab-pane>
          <el-tab-pane>
            <template #label>excel上传记录</template>
            <span>资料表</span>
            <el-table :data="documentExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1" class="pass">已通过</span>
                  <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="docESizeChange" @current-change="docECurChange"
                :current-page="docEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="docEQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="docETotal">
              </el-pagination>
            </el-config-provider>
            <span>实验表</span>
            <el-table :data="experimentExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1" class="pass">已通过</span>
                  <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="expESizeChange" @current-change="expECurChange"
                :current-page="expEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="expEQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="expETotal">
              </el-pagination>
            </el-config-provider>
            <span>合约表</span>
            <el-table :data="baseExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1" class="pass">已通过</span>
                  <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="baseSizeChange" @current-change="baseCurChange"
                :current-page="baseQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="baseQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="baseTotal">
              </el-pagination>
            </el-config-provider>
            <span>甩项表</span>
            <el-table :data="jiltExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1" class="pass">已通过</span>
                  <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="jiltSizeChange" @current-change="jiltCurChange"
                :current-page="jiltQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="jiltQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="jiltTotal">
              </el-pagination>
            </el-config-provider>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 技术负责人 -->

      <div class="progress" v-if="roleName == '技术负责人' || admins.indexOf(roleName) > 0">
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">技术负责人-excel上传记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getPlanExcel" class="select" size="small" v-model="planQuery.status"
                placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getPlanExcel" class="select2" size="small" v-model="planQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-table :data="planExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="上报时间" prop="createTime" :formatter="dateFormate"></el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="proESizeChange" @current-change="proECurChange"
              :current-page="planQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="planQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="planTotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 施工员 进度 记录-->
      <div class="progress" v-if="roleName == '施工员' || admins.indexOf(roleName) > 0">
        <!-- <div class="progress" v-if="arr.indexOf('progress') > -1"> -->
        <div class="report">
          <el-row>
            <el-col :span="17"><span class="title">施工员-进度表填报记录</span></el-col>
            <el-col :span="7">
              <el-input placeholder="请输入名称" v-model="proQuery.query" @keyup.enter="getProgress()" clearable
                @clear="getProgress()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getProgress()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="progress" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="进度单编号" prop="progressNo"></el-table-column>
            <el-table-column label="起桩号" prop="beginPile"></el-table-column>
            <el-table-column label="止桩号" prop="endPile"></el-table-column>
            <el-table-column label="项目编号" prop="itemNo"></el-table-column>
            <el-table-column label="项目名称" prop="itemName"></el-table-column>
            <el-table-column label="上报时间"><template
                v-slot="scope"><span>{{ DateFormat(scope.row.createTime) }}</span></template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 0">未审核</span>
              </template>
            </el-table-column>
            <el-table-column label="审核主管">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">无</span>
                <span v-else>{{ scope.row.reviewUserId }}</span>
              </template>
            </el-table-column>
            <el-table-column label="原因">
              <template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-config-provider :locale="locale">
            <el-pagination @size-change="proSizeChange" @current-change="proCurChange" :current-page="proQuery.pagenum"
              :page-sizes="[5, 10, 20]" :page-size="proQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="proTotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <!-- 施工员 excel上传记录 -->
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">施工员-excel上传记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getProgressExcel" class="select2" size="small" v-model="proEQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <!-- <el-col :span="7">
							<el-input placeholder="请输入excel表名称" v-model="proEQuery.query" @keyup.enter="getBaseList()"
								clearable @clear="getBaseList()">
								<template #append>
									<el-button icon="el-icon-search" @click="getBaseList()"></el-button>
								</template>
							</el-input>
						</el-col> -->
          </el-row>
          <el-table :data="progressExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="上传时间"><template v-slot="scope">{{DateFormat(scope.row.createTime)}}</template>
            </el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
				  DateFormat(scope.row.updateTime)
				}}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
				    scope.row.status == 2 ? scope.row.reason : "无"
				  }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="baseSizeChange" @current-change="baseCurChange"
              :current-page="baseQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="baseQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="baseTotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 试验员 试验检测记录 -->
      <div class="experiment" v-if="roleName == '试验员' || admins.indexOf(roleName) > 0">
        <!-- <div class="experiment" v-if="arr.indexOf('experiment') > -1"> -->
        <div class="report">
          <el-row>
            <el-col :span="17"><span class="title">试验员-试验检测填报记录</span></el-col>
            <el-col :span="7">
              <el-input placeholder="请输入试验名称" v-model="expQuery.query" @keyup.enter="getExperiment()" clearable
                @clear="getExperiment()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getExperiment()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="experiment" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column min-width="160" label="工程名称" prop="projectName"></el-table-column>
            <el-table-column min-width="120" label="材料及实体名称" prop="materialEntityName"></el-table-column>
            <el-table-column label="上报时间" min-width="130"><template
                v-slot="scope"><span>{{ DateFormat(scope.row.createTime) }}</span></template>
            </el-table-column>
            <el-table-column label="检测项目" min-width="160" prop="testItem"></el-table-column>
            <el-table-column label="检测频率" min-width="160" prop="testFrequency"></el-table-column>
            <el-table-column label="现场进度工程量" prop="progressQuantity"></el-table-column>
            <el-table-column label="进度应检数量" prop="actualRequiredQuantity"></el-table-column>
            <el-table-column label="实际检测数量" prop="progressRequiredQuantity"></el-table-column>
            <el-table-column label="已取检测报告数量" prop="obtainedQuantity"></el-table-column>
            <el-table-column label="未取检测报告数量" prop="notObtainedQuantity"></el-table-column>
            <el-table-column label="检测合格数量" prop="passQuantity"></el-table-column>
            <el-table-column label="检测不合格数量" prop="notPassQuantity"></el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 0">未审核</span>
              </template>
            </el-table-column>
            <el-table-column label="审核主管">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">无</span>
                <span v-else>{{ scope.row.reviewId }}</span>
              </template>
            </el-table-column>
            <el-table-column label="原因">
              <template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="expSizeChange" @current-change="expCurChange" :current-page="expQuery.pagenum"
              :page-sizes="[5, 10, 20]" :page-size="expQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="expTotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">实验员-excel上传记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getExperimentExcel" class="select" size="small" v-model="expEQuery.status"
                placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id"> </el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" end-placeholder="请选择截至时间" :editable="false" @change="getDate">
              </el-date-picker>
              <el-select @change="getExperimentExcel" class="select2" size="small" v-model="expEQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id"></el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="请输入excel表名称" v-model="expEQuery.query" @keyup.enter="getExperimentExcel()"
                clearable @clear="getExperimentExcel()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getExperimentExcel()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="experimentExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="expESizeChange" @current-change="expECurChange"
              :current-page="expEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="expEQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="expETotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <!-- 试验员上传问题表 -->
        <div class="excelQus">
          <el-row>
            <el-col :span="17"><span class="title">实验员-excel上传问题表记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getExperimentExcelQus" class="select" size="small" v-model="expEQueryQus.status"
                placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id"></el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDateQus">
              </el-date-picker>
              <el-select @change="getExperimentExcelQus" class="select2" size="small" v-model="expEQueryQus.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="请输入excel表名称" v-model="expEQueryQus.query" @keyup.enter="getExperimentExcelQus()"
                clearable @clear="getExperimentExcelQus()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getExperimentExcelQus()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="experimentExcelQus" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template></el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="expESizeChangeQus" @current-change="expECurChangeQus"
              :current-page="expEQueryQus.pagenum" :page-sizes="[5, 10, 20]" :page-size="expEQueryQus.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="expETotalQus">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 资料员 资料记录 -->
      <div class="document" v-if="roleName == '资料员' || admins.indexOf(roleName) > 0">
        <!-- <div class="document" v-if="arr.indexOf('document') > -1"> -->
        <div class="report">
          <el-row>
            <el-col :span="17"><span class="title">资料员-资料填报记录</span></el-col>
            <el-col :span="7">
              <el-input placeholder="请输入资料名称" v-model="docQuery.query" @keyup.enter="getDocument()" clearable
                @clear="getDocument()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getDocument()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="document" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="册数" prop="volume"></el-table-column>
            <el-table-column label="文件材料名称" min-width="160px" prop="documentContentName"></el-table-column>
            <el-table-column label="资料说明" prop="documentDescription"></el-table-column>
            <el-table-column label="资料完成情况说明" prop="documentCompleteDescription"></el-table-column>
            <el-table-column label="总数" prop="totalNum"></el-table-column>
            <el-table-column label="编制数" prop="organizationNum"></el-table-column>
            <el-table-column label="监理签认数" prop="supervisorNum"></el-table-column>
            <el-table-column label="设计签认数" prop="designNum"></el-table-column>
            <el-table-column label="建设单位签认数" prop="constructionNum"></el-table-column>
            <el-table-column label="存档原件数" prop="archiveOriginalNum"></el-table-column>
            <el-table-column label="存档复印数" prop="archiveCopyNum"></el-table-column>
            <el-table-column label="上报时间" min-width="120px"><template
                v-slot="scope"><span>{{ DateFormat(scope.row.createTime) }}</span></template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 0">未审核</span>
              </template>
            </el-table-column>
            <el-table-column label="审核主管">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">无</span>
                <span v-else>{{ scope.row.reviewUserId }}</span>
              </template>
            </el-table-column>
            <el-table-column label="原因">
              <template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-config-provider :locale="locale">
            <el-pagination @size-change="docSizeChange" @current-change="docCurChange" :current-page="docQuery.pagenum"
              :page-sizes="[5, 10, 20]" :page-size="docQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="docTotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">资料员-excel上传记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getDocumentExcel" class="select" size="small" v-model="docEQuery.status"
                placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getDocumentExcel" class="select2" size="small" v-model="docEQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="请输入excel表名称" v-model="docEQuery.query" @keyup.enter="getDocumentExcel()" clearable
                @clear="getDocumentExcel()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getDocumentExcel()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="documentExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="docESizeChange" @current-change="docECurChange"
              :current-page="docEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="docEQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="docETotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <!-- 资料员上传问题表 -->
        <div class="excelQus">
          <el-row>
            <el-col :span="17"><span class="title">资料员-excel上传问题表记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getDocumentExcelQus" class="select" size="small" v-model="docEQueryQus.status"
                placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDateQus">
              </el-date-picker>
              <el-select @change="getDocumentExcelQus" class="select2" size="small" v-model="docEQueryQus.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="请输入excel表名称" v-model="docEQueryQus.query" @keyup.enter="getDocumentExcelQus()"
                clearable @clear="getDocumentExcelQus()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getDocumentExcelQus()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="documentExcelQus" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template></el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="docESizeChangeQus" @current-change="docECurChangeQus"
              :current-page="docEQueryQus.pagenum" :page-sizes="[5, 10, 20]" :page-size="docEQueryQus.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="docETotalQus">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 合约员 excel上传记录 -->
      <div class="contract" v-if="roleName == '合约员' || admins.indexOf(roleName) > 0">
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">合约员-excel上传记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getBaseList" class="select" size="small" v-model="baseQuery.status" placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getBaseList" class="select2" size="small" v-model="baseQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-table :data="baseExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="上传时间"><template v-slot="scope">{{DateFormat(scope.row.createTime)}}</template>
            </el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="baseSizeChange" @current-change="baseCurChange"
              :current-page="baseQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="baseQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="baseTotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">合约员-excel上传记录</span>
              <span class="leftTitle">状态：</span>
              <el-select @change="getJilt" class="select" size="small" v-model="jiltQuery.status" placeholder="请选择">
                <el-option v-for="item in docEType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getJilt" class="select2" size="small" v-model="jiltQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-table :data="jiltExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1" class="pass">已通过</span>
                <span v-if="scope.row.status == 2" class="disPass">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="上传时间"><template v-slot="scope">{{
                DateFormat(scope.row.createTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="jiltSizeChange" @current-change="jiltCurChange"
              :current-page="jiltQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="jiltQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="jiltTotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  import dateFormatFun from "../../plugins/dateFormat";
  export default {
    data() {
      return {
        status: 2,
        tabPosition: "left",
        // arr:['document','experiment','progress','contract'],
        admins: ["超级管理员", "公司管理员", "项目负责人", "公司负责人"],
        progress: [],
        planQuery: {
          pagenum: 1,
          pagesize: 5,
          query: "",
          					status: 0,
          					flag: 0,
          					startTime: "",
          					endTime: "",
        },
        planTotal: 0,
        planExcel: [],
        proQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        proTotal: 0,
        progressExcel: [],
        proEQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          status: 0,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        proETotal: 0,
        experiment: [],
        expQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        expTotal: 0,
        experimentExcel: [],
        expEQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          status: 0,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        expETotal: 0,
        //  试验员填报记录，excel问题表上传
        experimentExcelQus: [],
        expEQueryQus: {
          startTime: "",
          endTime: "",
          flag: "0",
          status: 0,

          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        expETotalQus: 0,

        document: [],
        docQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        docTotal: 0,
        documentExcel: [],
        docEQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          status: 0,
          flag: 0,
          startTime: "",
          endTime: "",
        },

        // 资料员填报记录，excel问题表上传
        documentExcelQus: [],
        docEQueryQus: {
          startTime: "",
          endTime: "",
          flag: "0",
          status: 0,

          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        docETotalQus: 0,
        docEType: [{
            id: 0,
            status: "全部",
          },
          {
            id: 1,
            status: "待审核",
          },
          {
            id: 2,
            status: "审核不通过",
          },
        ],
        dateType: [{
            id: 0,
            status: "全部",
          },
          {
            id: 1,
            status: "近1周",
          },
          {
            id: 2,
            status: "近1月",
          },
          {
            id: 3,
            status: "近3月",
          },
        ],
        dateValue: [null, null],
        dateValueQus: [null, null],
        docETotal: 0,

        baseExcel: [],
        baseQuery: {
          query: "",
          pageNum: 1,
          pageSize: 5,
          status: 0,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        baseTotal: 0,

        jiltExcel: [],
        jiltQuery: {
          query: "",
          pageNum: 1,
          pageSize: 5,
          status: 0,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        jiltTotal: 0,

        roleName: "",
      };
    },
    setup() {
      return {
        locale: zhCn,
      };
    },
    mounted() {
      this.roleName = window.sessionStorage.getItem("roleName");
      if (this.roleName == "超级管理员") {
        this.getAllDataReport();
      }
      if (this.roleName == "资料员") {
        this.getDocument();
        this.getDocumentExcel();
        this.getDocumentExcelQus();
      }
      if (this.roleName == "试验员") {
        this.getExperiment();
        this.getExperimentExcel();
        this.getExperimentExcelQus();
      }
      if (this.roleName == "施工员") {
        this.getProgress();
        this.getProgressExcel()
      }
      if (this.roleName == "合约员") {
        this.getBaseList();
        this.getJilt();
      }
      if (this.roleName == "技术负责人") {
        this.getPlanExcel();
      }

      // if(this.arr.indexOf('document') > -1){ this.getDocument(); this.getDocumentExcel();}
      // if(this.arr.indexOf('experiment')>-1){ this.getExperiment(); this.getExperimentExcel();}
      // if(this.arr.indexOf('progress')>-1){ this.getProgress(); }
      // if(this.arr.indexOf('baseList')>-1){ this.getBaseList(); }
    },
    computed: {
      DateFormat() {
        return function(date) {
          return dateFormatFun(date, 2);
        };
      },
    },
    methods: {
      getDate(e) {
        let obj;
        let fun;
        let obj1;
        let fun1;
        if (this.roleName == "资料员") {
          obj = this.docEQuery;
          fun = this.getDocumentExcel;
        }
        if (this.roleName == "试验员") {
          obj = this.expEQuery;
          fun = this.getExperimentExcel;
        }
        if (this.roleName == "施工员") {
          obj = this.proEQuery
          fun = this.getProgressExcel
        }
        if (this.roleName == "合约员") {
          obj = this.baseQuery;
          fun = this.getBaseList
          obj1 = this.jiltQuery;
          fun1 = this.getJilt
        }
        if(this.roleName == "技术负责人") {
          obj = this.planQuery;
          fun = this.getPlanExcel
        }
        if (e) {
          obj.startTime = dateFormatFun(e[0], 2);
          obj.endTime = dateFormatFun(e[1], 2);
          obj1.startTime = dateFormatFun(e[0], 2);
          obj1.endTime = dateFormatFun(e[1], 2);
        } else {
          obj.startTime = "";
          obj.endTime = "";
          obj1.startTime = "";
          obj1.endTime = "";
        }
        fun();
        fun1()
      },
      getDateQus(e) {
        let obj;
        let fun;
        if (this.roleName == "资料员") {
          obj = this.docEQueryQus;
          fun = this.getDocumentExcelQus;
        }
        if (this.roleName == "试验员") {
          obj = this.expEQueryQus;
          fun = this.getExperimentExcelQus;
        }
        if (this.roleName == "施工员") {}
        if (this.roleName == "合约员") {}
        if (e) {
          obj.startTime = dateFormatFun(e[0], 2);
          obj.endTime = dateFormatFun(e[1], 2);
        } else {
          obj.startTime = "";
          obj.endTime = "";
        }
        fun();
      },
      dateFormate(res) {
        return dateFormatFun(res.createTime, 4);
      },
      //获取全部数据记录
      async getAllDataReport() {
        this.getDocument();
        this.getDocumentExcel();
        this.getExperiment();
        this.getExperimentExcel();
        this.getBaseList();
        this.getProgress();
        this.getProgressExcel();
        this.getJilt();
        this.getExperimentExcelQus();
        this.getDocumentExcelQus();
      },

      //施工计划表数据
      async getPlanExcel() {
        this.planQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "TechnicalDirector/getReportedConstructionPlan", {
            params: this.planQuery,
          }
        );
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.msg);
        this.planExcel = res.data.list;
        this.planTotal = res.data.total;
      },

      // 获取试验数据
      async getExperiment() {
        this.expQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimenter/getReportedExperiment", {
            params: this.expQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experiment = res.data.list;
        this.expTotal = res.data.total;
      },
      async getExperimentExcel() {
        this.expEQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimenter/getReportedExperimentExcel", {
            params: this.expEQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experimentExcel = res.data.list;
        this.expETotal = res.data.total;
      },
      // 试验员员填报记录，excel问题表上传
      async getExperimentExcelQus() {
        this.expEQueryQus.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimenter/getReportedExperimentExcel", {
            params: this.expEQueryQus,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experimentExcelQus = res.data.list;
        this.expETotalQus = res.data.total;
      },

      expSizeChange(newSize) {
        this.expQuery.pagesize = newSize;
        this.getExperiment();
      },
      expCurChange(newPage) {
        this.expQuery.pagenum = newPage;
        this.getExperiment();
      },
      expESizeChange(newSize) {
        this.expEQuery.pagesize = newSize;
        this.getExperimentExcel();
      },
      expECurChange(newPage) {
        this.expEQuery.pagenum = newPage;
        this.getExperimentExcel();
      },
      // 试验员填报记录，excel问题表上传数据分页
      expESizeChangeQus(newSize) {
        this.expEQueryQus.pagesize = newSize;
        this.getExperimentExcel();
      },
      expECurChangeQus(newPage) {
        this.expEQueryQus.pagenum = newPage;
        this.getExperimentExcelQus();
      },
      // 获取资料数据
      async getDocument() {
        this.docQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documenter/getReportedDocument", {
            params: this.docQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        console.log("资料填报记录", res);
        this.document = res.data.list;
        this.docTotal = res.data.total;
      },
      async getDocumentExcel() {
        this.docEQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documenter/getReportedDocumentExcel", {
            params: this.docEQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.documentExcel = res.data.list;
        this.docETotal = res.data.total;
      },
      // 资料员上传问题表记录
      async getDocumentExcelQus() {
        this.docEQueryQus.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documenter/getReportedProblemDocumentExcel", {
            params: this.docEQueryQus,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);

        console.log("表格上传问题表记录", res);
        this.documentExcelQus = res.data.list;
        this.docETotalQus = res.data.total;
      },
      //上传问题表页数
      docESizeChangeQus(newSize) {
        this.docEQueryQus.pagesize = newSize;
        this.getDocumentExcelQus();
      },
      docECurChangeQus(newPage) {
        this.docEQueryQus.pagenum = newPage;
        this.getDocumentExcelQus();
      },
      docSizeChange(newSize) {
        this.docQuery.pagesize = newSize;
        this.getDocument();
      },
      docCurChange(newPage) {
        this.docQuery.pagenum = newPage;
        this.getDocument();
      },
      docESizeChange(newSize) {
        this.docEQuery.pagesize = newSize;
        this.getDocumentExcel();
      },
      docECurChange(newPage) {
        this.docEQuery.pagenum = newPage;
        this.getDocumentExcel();
      },
      // 获取进度表数据
      async getProgress() {
        this.proQuery.curItemId = window.sessionStorage.getItem("activeItem");
        // this.proQuery.curItemId = 3;
        const {
          data: res
        } = await this.$http.get(
          "progresser/getReportedProgress", {
            params: this.proQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.progress = res.data.list;
        this.proTotal = res.data.total;
      },
      async getProgressExcel() {
        this.proEQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "progresser/getReportedProgressExcel", {
            params: this.proEQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.progressExcel = res.data.list;
        this.proETotal = res.data.total;
      },
      proSizeChange(newSize) {
        this.proQuery.pagesize = newSize;
        this.getProgress();
      },
      proCurChange(newPage) {
        this.proQuery.pagenum = newPage;
        this.getProgress();
      },
      proESizeChange(newSize) {
        this.proEQuery.pagesize = newSize;
        this.getProgressExcel();
      },
      proECurChange(newPage) {
        this.proEQuery.pagenum = newPage;
        this.getProgressExcel();
      },
      // 获取基础清单表数据
      async getBaseList() {
        this.baseQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "contractor/getReportedBaseList", {
            params: this.baseQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        console.log(res);
        this.baseExcel = res.data.list;
        this.baseTotal = res.data.total;
      },
      baseSizeChange(newSize) {
        this.baseQuery.pageSize = newSize;
        this.getBaseList();
      },
      baseCurChange(newPage) {
        this.baseQuery.pageNum = newPage;
        this.getBaseList();
      },
      // 获取甩项工程量数据
      async getJilt() {
        this.jiltQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "contractor/getReportedJiltQuantity", {
            params: this.jiltQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.jiltExcel = res.data.list;
        this.jiltTotal = res.data.total;
      },
      jiltSizeChange(newSize) {
        this.jiltQuery.pageSize = newSize;
        this.getJilt();
      },
      jiltCurChange(newPage) {
        this.jiltQuery.pageNum = newPage;
        this.getJilt();
      },
    },
  };
</script>

<style lang="less" scoped>
  .title,
  .leftTitle {
    font-size: 14px;
    color: #666;
    display: block;
    margin-right: 20px;
  }

  .leftTitle {
    margin-right: 0;
  }

  .colLeft {
    display: flex;
    align-items: center;
  }

  .select {
    width: 120px;
    margin-right: 20px;
  }

  .select2 {
    width: 120px;
    margin-left: 20px;
  }

  .pass {
    color: blue;
  }

  .disPass {
    color: red;
    font-weight: bolder;
  }
</style>
